import React, { useState, useEffect } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    Button,
    CardBody,
    Form,
    FormGroup,
    Input,
    CardFooter,
} from 'reactstrap';
import axios from 'axios';
import NProgress from 'nprogress';

import Header from '../../components/Headers/Header';
import ResponseCard from './ResponseCard';
import { toBase64 } from '../../utils';

export default function (props) {
    const [data, setData] = useState({});
    const [fileExcel, setFileExcel] = useState({});
    const [isEditing, setEdit] = useState(false);

    const fetchData = async () => {
        NProgress.start();
        try {
            const result = await axios.get('/api/v2/bot');
            setData(result.data.data);
        } catch (error) {
            alert(error);
        }
        NProgress.done();
    };

    const updateData = async () => {
        try {
            const result = await axios.put('/api/v2/bot/update', data);
            alert('Update sukses');
            setData(result.data.data);
        } catch (error) {
            alert(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleOnChange = (field, value) => {
        setEdit(true);
        setData({ ...data, [field]: value });
    };

    const handleOnchangeListButton = () => {
        setEdit(true);
    };

    const handleCommandChange = async (index, el) => {
        setEdit(true);
        const commands = data.commands;

        let changes = {};

        if (commands[index].type === 'file' && el.target.name === 'action') {
            if (el.target.files.length) {
                try {
                    changes[el.target.name] = await toBase64(el.target.files[0]);
                    changes.file_name = el.target.files[0].name;
                } catch (error) {
                    console.log(error);
                }
            } else {
                changes[el.target.name] = null;
            }
        } else {
            changes[el.target.name] = el.target.value;
        }

        commands[index] = { ...commands[index], ...changes };

        setData({ ...data, commands: commands });
    };

    const addCommand = () => {
        setData({ ...data, commands: [...data.commands, {}] });
    };

    const removeCommand = (index) => {
        setData({
            ...data,
            commands: data.commands.filter((v, i) => i !== index),
        });
    };

    const renderCommandList = (commands) => {
        return commands.map((command, index) => {
            return (
                <ResponseCard
                    data={command}
                    handleOnChange={handleCommandChange}
                    index={index}
                    key={index}
                    onRemove={() => removeCommand(index)}
                    onSave={updateData}
                />
            );
        });
    };

    const sendFileExcel = async () => {
        if (fileExcel) {
            let req = {};
            const data = await toBase64(fileExcel);
            req.data = data;
            // console.log('cobv', stringData);
            try {
                const result = await axios.post('/api/v2/bot/importFromExcel', req);
                fetchData();
                alert('success update auto reply');
            } catch (error) {
                alert('error', error);
            }
        }
    };

    return (
        <>
            <Header />
            <Container className='mt--7' fluid>
                <Row className='mb-3'>
                    <Col lg='8'>
                        <Card className='shadow'>
                            <CardHeader className='border-0'>
                                <Row className='align-items-center'>
                                    <Col xs='8'>
                                        <h2 className='mb-0'>Auto-Reply</h2>
                                    </Col>
                                    <Col xs='4' className='text-right'></Col>
                                </Row>
                            </CardHeader>
                            <CardBody className='border-0'>
                                <Form>
                                    <Row className='mb-5'>
                                        <Col>
                                            <div className='custom-control custom-checkbox'>
                                                <input
                                                    className='custom-control-input'
                                                    id='active'
                                                    type='checkbox'
                                                    checked={data.active || false}
                                                    onChange={() => handleOnChange('active', !data.active)}
                                                    name='active'
                                                />
                                                <label className='custom-control-label' htmlFor='active'>
                                                    Auto-Reply Aktif
                                                </label>
                                            </div>
                                        </Col>
                                    </Row>
                                    {/* // interval untuk auto-reply */}
                                    {/* <Row>
                                        <Col>
                                            <FormGroup>
                                                <label htmlFor='interval'>
                                                    Interval auto-reply (Jam)
                                                </label>
                                                <Input
                                                    id='interval'
                                                    type='number'
                                                    className='form-control-alternative'
                                                    placeholder='Masukkan interval autoreply (Jam)'
                                                    value={data.interval || 1}
                                                    onChange={(e) =>
                                                        handleOnChange(
                                                            e.target.name,
                                                            e.target.value
                                                        )
                                                    }
                                                    name='interval'
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row> */}
                                    {/* //  */}
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <label htmlFor='message'>Pesan menu awal</label>
                                                <Input
                                                    id='message'
                                                    rows='10'
                                                    type='textarea'
                                                    className='form-control-alternative'
                                                    placeholder='Masukkan pesan menu awal*'
                                                    name='message'
                                                    value={data.message || ''}
                                                    onChange={(el) =>
                                                        handleOnChange(el.target.name, el.target.value)
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>

                            <CardFooter>
                                <div className='d-flex'>
                                    {data.commands ? (
                                        false
                                    ) : (
                                        <Button outline className='btn-info mr-auto' onClick={addCommand}>
                                            <span className='btn-inner--icon'>
                                                <i className='ni ni-fat-add' />
                                            </span>
                                            <span className='btn-inner--text'>Tambah respon</span>
                                        </Button>
                                    )}
                                    <Button
                                        color='primary ml-auto'
                                        onClick={updateData}
                                        disabled={!isEditing}
                                    >
                                        Simpan
                                    </Button>
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col lg='4'>
                        <Card>
                            <CardHeader className='border-0'>
                                <Row className='align-items-center'>
                                    <Col xs='8'>
                                        <h3 className='mb-0'>Import From Excel</h3>
                                    </Col>
                                    <Col xs='4' className='text-right'></Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    <Row>
                                        <Col xs='12'>
                                            <div>
                                                <Input
                                                    className='form-control-alternative'
                                                    id='customFileLang'
                                                    type='file'
                                                    onChange={(el) => {
                                                        setFileExcel(el.target.files[0]);
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                            <CardFooter>
                                <div className='d-flex'>
                                    <Button color='primary' onClick={sendFileExcel}>
                                        Submit
                                    </Button>
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <h5 className='text-gray'>Daftar respon</h5>
                    </Col>
                </Row>

                <Row>
                    <Col lg='8'>{data.commands && renderCommandList(data.commands)}</Col>
                </Row>
                <Row>
                    <Col lg='8'>
                        <Button outline className='btn-info mr-auto' onClick={addCommand}>
                            <span className='btn-inner--icon'>
                                <i className='ni ni-fat-add' />
                            </span>
                            <span className='btn-inner--text'>Tambah respon</span>
                        </Button>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
