import React, { useState, useEffect } from 'react';
import {
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Navbar,
    Nav,
    Container,
    Media,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import defaultUserPhoto from '../../assets/img/user_icon.png';
import NProgress from 'nprogress';

function AdminNavbar(props) {
    const { onLogout } = props;
    const [data, setData] = useState({});

    const fetchData = async () => {
        NProgress.start();
        try {
            const result = await axios.get('/api/v2/session/getAuthStatus');
            setData(result.data.data);
            console.log('resultData', result.data.data);
        } catch (err) {
            // alert(err);
            console.log(err);
        }
        NProgress.done();
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Navbar className='navbar-top navbar-dark' expand='md' id='navbar-main'>
            <Container fluid>
                <Link className='h4 mb-0 text-white text-uppercase d-none d-md-inline-block' to='/'>
                    {props.brandText}
                </Link>
                <h4 style={{ color: 'white' }}>WhatsApp Status : {data.clientStatus}</h4>

                <Nav className='align-items-center d-none d-md-flex' navbar>
                    <UncontrolledDropdown nav>
                        <DropdownToggle className='pr-0' nav>
                            <Media className='align-items-center'>
                                <span className='avatar avatar-sm rounded-circle'>
                                    <img alt='User' src={props.userPhoto || defaultUserPhoto} />
                                </span>
                                <Media className='ml-2 d-none d-md-block'>
                                    <span className='mb-0 text-sm font-weight-bold'>
                                        {props.userName || 'Admin'}
                                    </span>
                                </Media>
                            </Media>
                        </DropdownToggle>
                        <DropdownMenu className='dropdown-menu-arrow' right>
                            <DropdownItem href='#logout' onClick={onLogout}>
                                <i className='ni ni-user-run' />
                                <span>Logout</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Nav>
            </Container>
        </Navbar>
    );
}

export default AdminNavbar;
