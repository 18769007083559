import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import axios from 'axios';
import AdminLayout from './layouts/AdminLayout';
import AuthLayout from './layouts/AuthLayout';
import config from './config.json';
import { useAuth } from './contexts/auth';

axios.defaults.baseURL = config.baseURL;

function App(props) {
    const { isAuthenticated } = useAuth();
    return (
        <BrowserRouter>
            <Switch>{isAuthenticated ? <Route component={AdminLayout} /> : <Route component={AuthLayout} />}</Switch>
        </BrowserRouter>
    );
}

export default App;
