import { Route } from 'react-router-dom';
import Dashboard from '../views/Dashboard';
import Users from '../views/Users';
import Bot from '../views/Bot';
import Login from '../views/Login';
import QRCode from '../views/QRCode';

export const routes = [
    {
        path: '/dashboard',
        name: 'Dashboard',
        icon: 'ni ni-tv-2 text-primary',
        component: Dashboard,
        layout: '/admin',
    },
    {
        path: '/users',
        name: 'Kelola User',
        icon: 'ni ni-single-02 text-yellow',
        component: Users,
        layout: '/admin',
    },
    {
        path: '/autoreply',
        name: 'Kelola Auto-Reply',
        icon: 'ni ni-send text-blue',
        component: Bot,
        layout: '/admin',
    },
    {
        path: '/setupAuth',
        name: 'Kelola Sesi Whatsapp',
        icon: 'ni ni-settings',
        component: QRCode,
        layout: '/admin',
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        layout: '/auth',
    },
];

export const renderRoutes = ({ layout }) => {
    return routes.map((prop, key) => {
        if (prop.layout === layout) {
            return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
        } else {
            return null;
        }
    });
};

export const getBrandText = ({ path }) => {
    for (let i = 0; i < routes.length; i++) {
        if (path.indexOf(routes[i].layout + routes[i].path) !== -1) {
            return routes[i].name;
        }
    }
    return 'Brand';
};
