import React from "react";
import { Modal, Button } from "reactstrap";

function DeleteUserModal({ isOpen, toggle, selectedData, onDelete }) {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="modal-dialog-centered"
      size="sm"
    >
      <div className="modal-header">
        <h3 className="modal-title">Hapus user</h3>
        <button
          aria-label="Close"
          className="close"
          type="button"
          onClick={toggle}
        >
          <span aria-hidden={true}>
            <i className="fas fa-times" />
          </span>
        </button>
      </div>
      <div className="modal-body">
        {selectedData ? (
          <p>
            Apakah anda yakin akan menghapus user "
            <span className="font-weight-bold">{selectedData.name}</span>"
          </p>
        ) : null}
      </div>

      <div className="modal-footer">
        <Button
          color="secondary"
          data-dismiss="modal"
          type="button"
          onClick={toggle}
        >
          Cancel
        </Button>
        <Button
          color="danger"
          type="submit"
          onClick={() => onDelete(selectedData._id)}
        >
          Hapus
        </Button>
      </div>
    </Modal>
  );
}

export default DeleteUserModal;
