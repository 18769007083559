import React from 'react';
import {
    Table,
    Button,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    Media,
} from 'reactstrap';
// import defaultUserPhoto from '../../assets/img/user_icon.png';

function UserTable({ users, onLoading, toggleModal }) {
    /* 
  TODO: Complete user table
  - Add loading
  - Add pagination
  - Add search
  */

    const renderUsers = (users) => {
        return users.map((prop, key) => {
            return (
                <tr key={key}>
                    <th scope='row'>{key + 1}</th>
                    <td>
                        <Media className='align-items-center'>
                            {/* <a
                className="avatar rounded-circle mr-3"
                href="#"
                onClick={(e) => e.preventDefault()}
              >
                <img alt="User photos" src={prop.photo || defaultUserPhoto} />
              </a> */}
                            <span className='mb-0 text-sm'>{prop.name}</span>
                        </Media>
                    </td>
                    <td>{prop.username}</td>
                    <td>{prop.role === 'user' ? 'Operator' : 'Admin'}</td>
                    <td style={{ width: '1%', whiteSpace: 'nowrap' }}>
                        <Button
                            color='default'
                            onClick={() => toggleModal('updateModal', prop)}
                            size='sm'
                            type='button'
                        >
                            Edit
                        </Button>
                        <Button color='danger' onClick={() => toggleModal('deleteModal', prop)} size='sm'>
                            Hapus
                        </Button>
                        <UncontrolledDropdown>
                            <DropdownToggle
                                className='btn-icon-only text-light'
                                href='#'
                                role='button'
                                size='sm'
                                color=''
                                onClick={(e) => e.preventDefault()}
                            >
                                <i className='fas fa-ellipsis-v' />
                            </DropdownToggle>
                            <DropdownMenu className='dropdown-menu-arrow' right>
                                <DropdownItem href='#' onClick={(e) => e.preventDefault}>
                                    Reset password
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </td>
                </tr>
            );
        });
    };

    return (
        <Table className='align-items-center table-flush' responsive>
            <thead className='thead-light'>
                <tr>
                    <th scope='col'>No</th>
                    <th scope='col'>Nama</th>
                    <th scope='col'>Username</th>
                    <th scope='col'>Role</th>
                    <th style={{ width: '1%', whiteSpace: 'nowrap' }}>Aksi</th>
                </tr>
            </thead>
            <tbody>
                {users.length > 0 ? (
                    renderUsers(users)
                ) : (
                    <tr>
                        <td colSpan='5'>
                            <p className='text-center'>Belum ada data</p>
                        </td>
                    </tr>
                )}
            </tbody>
        </Table>
    );
}

export default UserTable;
