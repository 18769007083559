import React from 'react';
import { Form, Row, Col, FormGroup, Input, Modal, Button } from 'reactstrap';
import { useForm } from 'react-hook-form';

function CreateUserModal({ isOpen, toggle, onSubmit }) {
    const { register, handleSubmit, errors } = useForm();
    const formId = 'createUserForm';

    return (
        <Modal className='modal-dialog-centered' isOpen={isOpen} toggle={toggle} size='sm'>
            <div className='modal-header'>
                <h3 className='modal-title'>Tambah user</h3>
                <button aria-label='Close' className='close' type='button' onClick={toggle}>
                    <span aria-hidden={true}>
                        <i className='fa fa-times' />
                    </span>
                </button>
            </div>
            <div className='modal-body'>
                <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <label htmlFor='selectRole'>Select Role</label>
                                <select className='form-control' id='selectRole' name='role' ref={register}>
                                    <option value='user'>Operator</option>
                                    <option value='admin'>Admin</option>
                                </select>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup className={errors.name && 'has-danger'}>
                                <Input
                                    className='form-control-alternative'
                                    placeholder={errors.name ? 'Nama tidak boleh kosong' : 'Nama'}
                                    name='name'
                                    innerRef={register({ required: true })}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup className={errors.username && 'has-danger'}>
                                <Input
                                    className='form-control-alternative'
                                    placeholder={errors.username ? 'Username tidak boleh kosong' : 'Username'}
                                    name='username'
                                    innerRef={register({ required: true })}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup className={errors.password && 'has-danger'}>
                                <Input
                                    className='form-control-alternative'
                                    placeholder={errors.password ? 'Password tidak boleh kosong' : 'Password'}
                                    type='password'
                                    name='password'
                                    innerRef={register({ required: true })}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div className='modal-footer'>
                <Button color='secondary' data-dismiss='modal' type='button' onClick={toggle}>
                    Cancel
                </Button>
                <Button color='primary' type='submit' form={formId}>
                    Tambah
                </Button>
            </div>
        </Modal>
    );
}

export default CreateUserModal;
