import React, { Component } from 'react';
import { Container, Row, Col, Card, CardHeader, Button, CardBody, CardFooter } from 'reactstrap';
import axios from 'axios';
import Header from '../../components/Headers/Header';
import NProgress from 'nprogress';
import io from 'socket.io-client';
import config from '../../config.json';
const socket = io(`${config.baseURL}`);

class QrCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            dataQr: { data: null },
            dataSession: '',
            loading: false,
        };
    }
    // const [data, setData] = useState({});
    // const [dataQr, setDataQr] = useState({});
    // const [dataSession, setDataSession] = useState({});
    // const [loading, setLoading] = useState(false);

    // const [socket, setSocket] = useState(io(`localhost:5000`));

    // if (socket !== null) {
    //     socket.on('connect', () => console.log('connect'));
    //     socket.on('qrcode', (data) => {
    //         console.log('dataQRD', data);
    //         setDataQr(data);
    //     });
    // }

    // console.log('data', data);

    fetchData = async () => {
        NProgress.start();
        try {
            const result = await axios.get('/api/v2/session/getAuthStatus');
            // setData(result.data.data);
            this.setState({ data: result.data.data });
            console.log('resultData', result.data.data);
        } catch (err) {
            // alert(err);
            console.log(err);
        }
        NProgress.done();
    };

    getQr = async () => {
        try {
            console.log('triggered');
            this.setState({ loading: true });
            const result = await axios.get('/api/v2/session/getQR');
            // if (result) {
            //     setLoading(false);
            // }
            // setDataQr(result.data);
        } catch (err) {
            console.log(err);
        }
        this.setState({ loading: false });
    };

    stopSession = async () => {
        try {
            const result = await axios.post('/api/v2/session/disconnectClient');
            // setDataSession(result.message);
            this.setState({ dataSession: result.message });
            alert('stop session berhasil');
            this.fetchData();
        } catch (err) {
            console.log(err);
        }
    };

    componentDidMount() {
        let { dataQr } = this.state;
        this.fetchData();
        socket.on('qrcode', (data) => {
            dataQr.data = data;
            this.setState({ dataQr });
        });
    }

    componentWillUnmount() {
        socket.disconnect();
    }

    render() {
        const { data, dataQr, dataSession, loading } = this.state;
        return (
            <>
                <Header />
                <Container className='mt--7' fluid>
                    <Row className='mb-3'>
                        <Col lg='8'>
                            <Card className='shadow'>
                                <CardHeader className='border-0'>
                                    <Row className='align-items-center'>
                                        <Col xs='8'>
                                            <h2 className='mb-0'>Kelola Sesi Whatsapp</h2>
                                        </Col>
                                        <Col xs='4' className='text-right'></Col>
                                    </Row>
                                </CardHeader>
                                <CardBody className='border-0'>
                                    <Row>
                                        <Col md='6'>
                                            <p>Status : </p>
                                        </Col>
                                        <Col md='6'>
                                            <p>{data.clientStatus || 'Not Connected'}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {loading ? (
                                            <button
                                                className='btn btn-primary btn-sm mb-2'
                                                type='button'
                                                disabled
                                            >
                                                <span
                                                    className='spinner-border spinner-border-sm'
                                                    role='status'
                                                    aria-hidden='true'
                                                ></span>
                                                Loading...
                                            </button>
                                        ) : (
                                            false
                                        )}
                                    </Row>
                                    {dataSession !== 'scanned' ? (
                                        <Row>
                                            <Col>
                                                <img src={dataQr.data}></img>
                                            </Col>
                                        </Row>
                                    ) : (
                                        false
                                    )}
                                </CardBody>

                                <CardFooter>
                                    {data.clientStatus === 'CONNECTED' ? (
                                        <Button
                                            color='danger'
                                            className='float-right'
                                            type='button'
                                            onClick={this.stopSession}
                                        >
                                            Stop Session
                                        </Button>
                                    ) : (
                                        <div>
                                            {dataQr.data ? (
                                                <Button
                                                    color='success'
                                                    className='float-right'
                                                    type='button'
                                                    onClick={() => {
                                                        this.fetchData();
                                                        this.setState({ dataSession: 'scanned' });
                                                        // setDataSession('scanned');
                                                    }}
                                                >
                                                    Done Scanning
                                                </Button>
                                            ) : (
                                                <Button
                                                    color='primary'
                                                    className='float-right'
                                                    type='button'
                                                    onClick={this.getQr}
                                                >
                                                    Get QR
                                                </Button>
                                            )}
                                        </div>
                                    )}
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default QrCode;
