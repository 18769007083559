import React from 'react';

function Header(props) {
    return (
        <div className='header pb-8 pt-5 pt-md-8' style={{ backgroundColor: '#095F0F' }}>
            {props.children}
        </div>
    );
}

export default Header;
