import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardHeader, Button } from "reactstrap";
import axios from "axios";
import NProgress from "nprogress";

import Header from "../../components/Headers/Header";
import UserTable from "./UserTable";
import CreateUserModal from "./CreateUserModal";
import EditUserModal from "./EditUserModal";
import DeleteUserModal from "./DeleteUserModal";

export default function (props) {
  const [users, setUsers] = useState([]);
  const [modalState, setModalState] = useState({});
  const [selectedData, setSelectedData] = useState(null);

  const fetchData = async () => {
    NProgress.start();
    try {
      const result = await axios.get("/api/v2/users/admin");
      setUsers(result.data.data);
    } catch (error) {
      console.log(error);
    }
    NProgress.done();
  };

  useEffect(() => {
    fetchData();
  }, []);

  const toggleModal = (modalId, data = null) => {
    setModalState({
      ...modalState,
      [modalId]: !modalState[modalId],
    });

    if (selectedData) {
      setSelectedData(null);
    } else {
      setSelectedData(data);
    }
  };

  const createUser = async (data) => {
    try {
      console.log("data", data);
      await axios.post("/api/v2/users/register", { ...data });
      alert("User created");
      fetchData();
    } catch (error) {
      console.error(error);
    }

    toggleModal("createModal");
  };

  const updateUser = async (data) => {
    try {
      await axios.put(`/api/v2/users/${data.id}`, data);
      alert("User updated");
      fetchData();
      toggleModal("updateModal");
    } catch (error) {
      console.error(error);
    }
  };

  const deleteUser = async (id) => {
    try {
      await axios.delete(`/api/v2/users/${id}`);
      alert("User deleted");
      fetchData();
      toggleModal("deleteModal");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h2 className="mb-0">Users</h2>
                  </Col>
                  <Col xs="4" className="text-right">
                    <Button
                      color="primary"
                      onClick={() => toggleModal("createModal")}
                    >
                      <span>
                        <i className="fas fa-plus fa-sm" />
                      </span>{" "}
                      Tambah
                    </Button>
                  </Col>
                </Row>
              </CardHeader>

              <UserTable users={users} toggleModal={toggleModal} />
            </Card>
          </Col>
        </Row>
      </Container>

      <CreateUserModal
        isOpen={modalState.createModal}
        toggle={() => toggleModal("createModal")}
        onSubmit={createUser}
      />

      <EditUserModal
        isOpen={modalState.updateModal}
        toggle={() => toggleModal("updateModal")}
        onSubmit={updateUser}
        selectedData={selectedData}
      />

      <DeleteUserModal
        isOpen={modalState.deleteModal}
        toggle={() => toggleModal("deleteModal")}
        onDelete={deleteUser}
        selectedData={selectedData}
      />
    </>
  );
}
