import React, { useState } from 'react';
import { NavLink as NavlinkRouter, Link } from 'react-router-dom';
import {
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Navbar,
    NavbarBrand,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    Nav,
    Media,
    Collapse,
    Row,
    Col,
    Form,
    InputGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
} from 'reactstrap';
import defaultUserPhoto from '../../assets/img/user_icon.png';

function Sidebar(props) {
    const { onLogout } = props;
    const [isCollapseOpen, setCollapseOpen] = useState(false);

    function renderLinks(routes) {
        return routes.map((prop, key) => {
            return (
                <NavItem key={key}>
                    <NavLink
                        to={prop.layout + prop.path}
                        tag={NavlinkRouter}
                        onClick={() => setCollapseOpen(false)}
                        activeClassName='active'
                    >
                        <i className={prop.icon} />
                        {prop.name}
                    </NavLink>
                </NavItem>
            );
        });
    }

    const { routes, logo, userPhoto } = props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
        navbarBrandProps = {
            to: logo.innerLink,
            tag: Link,
        };
    } else if (logo && logo.outterLink) {
        navbarBrandProps = {
            href: logo.outterLink,
            tag: '_blank',
        };
    }

    return (
        <Navbar className='navbar-vertical fixed-left navbar-light bg-white' expand='md' id='sidenav-main'>
            <Container fluid>
                <button className='navbar-toggler' type='button' onClick={() => setCollapseOpen(!isCollapseOpen)}>
                    <span className='navbar-toggler-icon' />
                </button>

                {logo ? (
                    <NavbarBrand className='pt-0' {...navbarBrandProps}>
                        <img alt={logo.imgAlt} className='navbar-brand-img' src={logo.imgSrc} />
                    </NavbarBrand>
                ) : null}

                <Nav className='align-items-center d-md-none'>
                    <UncontrolledDropdown nav>
                        <DropdownToggle nav>
                            <Media className='align-items-center'>
                                <span className='avatar avatar-sm rounded-circle'>
                                    <img alt='...' src={userPhoto || defaultUserPhoto} />
                                </span>
                            </Media>
                        </DropdownToggle>
                        <DropdownMenu className='dropdown-menu-arrow' right>
                            <DropdownItem className='noti-title' header tag='div'>
                                <h6 className='text-overflow m-0'>Welcome!</h6>
                            </DropdownItem>
                            <DropdownItem to='/admin/activity-history' tag={Link}>
                                <i className='ni ni-calendar-grid-58' />
                                <span>Activity</span>
                            </DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem href='#logout' onClick={onLogout}>
                                <i className='ni ni-user-run' />
                                <span>Logout</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Nav>

                <Collapse navbar isOpen={isCollapseOpen}>
                    <div className='navbar-collapse-header d-md-none'>
                        <Row>
                            {logo ? (
                                <Col className='collapse-brand' xs='6'>
                                    {logo.innerLink ? (
                                        <Link to={logo.innerLink}>
                                            <img alt={logo.imgAlt} src={logo.imgSrc} />
                                        </Link>
                                    ) : (
                                        <a href={logo.outterLink}>
                                            <img alt={logo.imgAlt} src={logo.imgSrc} />
                                        </a>
                                    )}
                                </Col>
                            ) : null}

                            <Col className='collapse-close' xs='6'>
                                <button
                                    className='navbar-toggler'
                                    type='button'
                                    onClick={() => setCollapseOpen(!isCollapseOpen)}
                                >
                                    <span />
                                    <span />
                                </button>
                            </Col>
                        </Row>
                    </div>

                    <Form className='mt-4 mb-3 d-md-none'>
                        <InputGroup className='input-group-rounded input-group-merge'>
                            <Input
                                aria-label='Search'
                                className='form-control-rounded form-control-prepended'
                                placeholder='Search'
                                type='search'
                            />
                            <InputGroupAddon addonType='prepend'>
                                <InputGroupText>
                                    <span className='fa fa-search' />
                                </InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                    </Form>

                    <Nav navbar>{renderLinks(routes)}</Nav>
                </Collapse>
            </Container>
        </Navbar>
    );
}

export default Sidebar;
