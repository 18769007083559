import React from 'react';
import {
    Col,
    Card,
    Button,
    CardBody,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Spinner,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../contexts/auth';

function Login(props) {
    const { register, handleSubmit, errors, clearErrors } = useForm();
    const formId = 'loginForm';
    const { isLoading, signIn } = useAuth();

    const login = (data) => {
        clearErrors();
        signIn(data);
    };

    return (
        <>
            <Col lg='5' md='7'>
                <Card className='bg-secondary shadow border-0'>
                    <CardBody className='px-lg-5 py-lg-5'>
                        <div className='text-center text-muted mb-4'>
                            <h5>Sign In</h5>
                        </div>

                        <Form role='form' id={formId} onSubmit={handleSubmit(login)}>
                            <FormGroup className={errors.username && 'has-danger'}>
                                <InputGroup className='input-group-alternative'>
                                    <InputGroupAddon addonType='prepend'>
                                        <InputGroupText>
                                            <i className='ni ni-email-83' />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        type='text'
                                        name='username'
                                        innerRef={register({ required: true })}
                                        placeholder={
                                            errors.username ? 'Username tidak boleh kosong' : 'Username'
                                        }
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup className={errors.password && 'has-danger'}>
                                <InputGroup className='input-group-alternative'>
                                    <InputGroupAddon addonType='prepend'>
                                        <InputGroupText>
                                            <i className='ni ni-lock-circle-open' />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder={
                                            errors.password ? 'Password tidak boleh kosong' : 'Password'
                                        }
                                        type='password'
                                        name='password'
                                        innerRef={register({ required: true })}
                                    />
                                </InputGroup>
                            </FormGroup>
                            {/* <div className="custom-control custom-control-alternative custom-checkbox"> */}
                            {/* <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted">Remember me</span>
                </label> */}
                            {/* </div> */}
                            <div className='text-center'>
                                <Button className='my-4' color='primary' type='submit' form={formId}>
                                    {isLoading && <Spinner size='sm' />}
                                    {' Sign in'}
                                </Button>
                            </div>
                        </Form>
                    </CardBody>
                </Card>
            </Col>
        </>
    );
}

export default Login;
