import React from 'react';
import { Container, Row, Col } from "reactstrap";

function AdminFooter(props){
    return (
        <footer className="footer">
            <Row className="align-items-center justify-content-xl-between">
                <Col xl="6">
                    <div className="copyright text-center text-xl-left text-muted">
                    © 2020 UIN Sunan Kalijaga
                    </div>
                </Col>
            </Row>
        </footer>
    );
}

export default AdminFooter;