import React from 'react';
import { Modal, Row, Col, Button, Form, FormGroup, Input } from 'reactstrap';
import { useForm } from 'react-hook-form';

function EditUserModal({ isOpen, toggle, selectedData, onSubmit }) {
    const { register, handleSubmit, errors } = useForm();
    const formId = 'updateUserForm';

    return (
        <Modal className='modal-dialog-centered' isOpen={isOpen} toggle={toggle} size='sm'>
            <div className='modal-header'>
                <h3 className='modal-title'>Edit user</h3>
                <button aria-label='Close' className='close' type='button' onClick={toggle}>
                    <span aria-hidden={true}>
                        <i className='fa fa-times' />
                    </span>
                </button>
            </div>
            <div className='modal-body'>
                {selectedData ? (
                    <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
                        <Input innerRef={register} name='id' hidden defaultValue={selectedData._id} />
                        <Row>
                            <Col>
                                <FormGroup>
                                    <label for='selectRole'>Select Role</label>
                                    <select
                                        className='form-control'
                                        id='selectRole'
                                        name='role'
                                        ref={register}
                                        defaultValue={selectedData.role}
                                    >
                                        <option value='user'>Operator</option>
                                        <option value='admin'>Admin</option>
                                    </select>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className={errors.name && 'has-danger'}>
                                    <Input
                                        innerRef={register({ required: true })}
                                        className='form-control-alternative'
                                        placeholder={errors.name ? 'Nama tidak boleh kosong' : 'Nama'}
                                        name='name'
                                        defaultValue={selectedData.name}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className={errors.username && 'has-danger'}>
                                    <Input
                                        innerRef={register({ required: true })}
                                        className='form-control-alternative'
                                        placeholder={
                                            errors.username ? 'Username tidak boleh kosong' : 'Username'
                                        }
                                        name='username'
                                        defaultValue={selectedData.username}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className={errors.password && 'has-danger'}>
                                    <Input
                                        className='form-control-alternative'
                                        placeholder={
                                            errors.password ? 'Password tidak boleh kosong' : 'Password'
                                        }
                                        type='password'
                                        name='password'
                                        innerRef={register({ required: true })}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                ) : null}
            </div>
            <div className='modal-footer'>
                <Button color='secondary' data-dismiss='modal' type='button' onClick={toggle}>
                    Close
                </Button>
                <Button color='primary' type='submit' form={formId}>
                    Simpan
                </Button>
            </div>
        </Modal>
    );
}

export default EditUserModal;
