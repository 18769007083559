import React from 'react';
import { Card, CardHeader, Container, Row, Col, CardBody, CardTitle, Alert } from 'reactstrap';
import axios from 'axios';
import NProgress from 'nprogress';
import Loader from 'react-loader-spinner';

import Header from '../../components/Headers/Header';
import Chart from '../../components/Charts/Chart';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            dataGeneral: {},
            dataMonthly: {},
            dataUsers: [],
            users: [],
            error: null,
            chartData: {
                labels: [],
                datasets: [
                    {
                        label: '',
                        data: [],
                        backgroundColor: ['rgba(255, 99, 132, 0.6)'],
                    },
                ],
            },
            rangeFilter: { start: '2021-07-01', end: '2021-08-01' },
        };
    }

    componentWillMount() {
        // this.getchartData(); // this should be this.getChartData();
        // this.getChartData();
        this.fetchUser();
        this.fetchStatsUser();
        this.fetchData();
    }

    getChartData() {
        // Ajax calls here
        this.setState({
            chartData: {
                labels: [1, 'Worcester', 'Springfield', 'Lowell', 'Cambridge', 'New Bedford'],
                datasets: [
                    {
                        label: 'Population',
                        data: this.state.dataMonthly.countMessagesEveryDay,
                        backgroundColor: ['rgba(255, 99, 132, 0.6)'],
                    },
                ],
            },
        });
    }

    fetchData = async () => {
        let { chartData, rangeFilter } = this.state;
        this.setError(null);
        NProgress.start();
        chartData = {
            datasets: [
                {
                    data: [],
                },
            ],
        };
        this.setState({ chartData });
        this.setState({ loading: true });
        try {
            const resultGeneral = await axios.get(
                `/api/v2/chats/generalDataForStatistic/${rangeFilter.start}/${rangeFilter.end}`
            );
            const resultMonthlyStats = await axios.get(
                `/api/v2/chats/monthlyStatistic/${rangeFilter.start}/${rangeFilter.end}`
            );

            let dataSets = resultMonthlyStats.data.data;
            let labelTanggal = [];
            for (let index = 0; index < dataSets.countMessagesEveryDay.length; index++) {
                labelTanggal.push(index);
            }

            this.setState({ dataMonthly: resultMonthlyStats.data.data });

            chartData = {
                labels: dataSets.date,
                datasets: [
                    {
                        label: 'Chats',
                        data: dataSets.countMessagesEveryDay,
                        backgroundColor: ['rgba(255, 99, 132, 0.6)'],
                    },
                    {
                        label: 'New Chats',
                        data: dataSets.countNewChatsEveryDay,
                        backgroundColor: ['blue'],
                    },
                ],
            };

            this.setState({ chartData });
            this.setState({ dataGeneral: resultGeneral.data.data });
            this.setState({ loading: false });
        } catch (error) {
            this.setError(error);
            console.error(error);
            this.setState({ loading: false });
        }

        NProgress.done();
    };

    fetchUser = async () => {
        NProgress.start();
        try {
            const result = await axios.get('/api/v2/users/admin');
            this.setState({ users: result.data.data });
        } catch (error) {
            console.log(error);
        }
        NProgress.done();
    };

    fetchStatsUser = async () => {
        let { rangeFilter } = this.state;
        let statsUsers = [];
        NProgress.start();
        try {
            this.setState({ loading: true });

            const resultUsers = await axios.get('/api/v2/users/admin');
            const users = resultUsers.data.data;

            users.forEach(async (user) => {
                const result = await axios.get(
                    `/api/v2/chats/statisticByIdAdmin/${rangeFilter.start}/${rangeFilter.end}/${user._id}`
                );
                statsUsers.push(result.data.data);
            });

            this.setState({ dataUsers: statsUsers });
            if (statsUsers.length > 0) {
                this.setState({ loading: false });
            }
        } catch (error) {
            console.log('error stats users', error);
            this.setState({ loading: false });
        }
        NProgress.done();
    };

    setError = (data) => {
        this.setState({ error: data });
    };

    render() {
        const { error, dataGeneral, dataMonthly, chartData, rangeFilter, dataUsers, loading } = this.state;
        return (
            <>
                <Header>
                    <Container fluid>
                        <div className='header-body'>
                            <Row>
                                <Col lg='12'>
                                    <Alert
                                        isOpen={error ? true : false}
                                        toggle={() => this.setError(null)}
                                        color='danger'
                                    >
                                        Terjadi kesalahan!{' '}
                                        <a className='alert-link' href='#' onClick={this.fetchData}>
                                            Muat ulang
                                        </a>
                                    </Alert>
                                </Col>

                                <Col lg='12' className='mb-4'>
                                    <Card>
                                        <CardHeader>
                                            {loading ? (
                                                <Row>
                                                    <Col md='4'></Col>
                                                    <Col md='4'>
                                                        <center>
                                                            <Loader
                                                                type='ThreeDots'
                                                                color='#00BFFF'
                                                                height={100}
                                                                width={100}
                                                            />
                                                        </center>
                                                    </Col>
                                                    <Col md='4'></Col>
                                                </Row>
                                            ) : (
                                                false
                                            )}
                                            <Row>
                                                <Col md='4'>
                                                    <CardTitle>
                                                        <h3>Grafik Chat Baru dengan Total Chat</h3>
                                                    </CardTitle>
                                                </Col>
                                                <Col md='3' className='text-right'>
                                                    <label for='rangeStart'>Start Date : </label>{' '}
                                                    <input
                                                        type='date'
                                                        id='rangeStart'
                                                        value={rangeFilter.start}
                                                        onChange={(e) => {
                                                            rangeFilter.start = e.target.value;
                                                            this.setState({ rangeFilter });
                                                        }}
                                                    />
                                                </Col>
                                                <Col md='3' className='text-right'>
                                                    <label for='rangeEnd'>End Date : </label>{' '}
                                                    <input
                                                        type='date'
                                                        id='rangeEnd'
                                                        value={rangeFilter.end}
                                                        onChange={(e) => {
                                                            rangeFilter.end = e.target.value;
                                                            this.setState({ rangeFilter });
                                                        }}
                                                    />
                                                </Col>
                                                <Col className='text-right'>
                                                    <button
                                                        className='btn btn-primary'
                                                        onClick={() => {
                                                            this.fetchData();
                                                            this.fetchStatsUser();
                                                        }}
                                                    >
                                                        OK
                                                    </button>
                                                </Col>
                                            </Row>
                                        </CardHeader>

                                        <CardBody>
                                            <Row>
                                                {chartData.datasets[0].data.length > 0 ? (
                                                    <Chart chartData={chartData} />
                                                ) : (
                                                    false
                                                )}
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md='6'>
                                    <Card className='card-stats mb-4 mb-xl-0'>
                                        <CardBody>
                                            <Row className='mb-2'>
                                                <div className='col'>
                                                    <CardTitle
                                                        tag='h5'
                                                        className='text-uppercase text-muted mb-0'
                                                    >
                                                        Transferred
                                                    </CardTitle>
                                                    <span className='h2 font-weight-bold mb-0'>
                                                        {dataGeneral.transferred || '0'}
                                                    </span>
                                                </div>
                                                <Col className='col-auto'>
                                                    <div className='icon icon-shape bg-primary text-white rounded-circle shadow'>
                                                        <i className='fas fa-chart-pie' />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md='6'>
                                                    <CardTitle
                                                        tag='h5'
                                                        className='text-uppercase text-muted mb-0'
                                                    >
                                                        Solved
                                                    </CardTitle>
                                                    <span className='h2 font-weight-bold mb-0'>
                                                        {dataGeneral.solved || '0'}
                                                    </span>
                                                </Col>
                                                <Col md='6'>
                                                    <CardTitle
                                                        tag='h5'
                                                        className='text-uppercase text-muted mb-0'
                                                    >
                                                        Unsolved
                                                    </CardTitle>
                                                    <span className='h2 font-weight-bold mb-0'>
                                                        {dataGeneral.unsolved || '0'}
                                                    </span>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md='3'>
                                    <Card className='card-stats mb-4 mb-xl-0'>
                                        <CardBody>
                                            <Row>
                                                <div className='col'>
                                                    <CardTitle
                                                        tag='h5'
                                                        className='text-uppercase text-muted mb-0'
                                                    >
                                                        New Chats
                                                    </CardTitle>
                                                    <span className='h2 font-weight-bold mb-0'>
                                                        {dataMonthly.totalNewChats || '0'}
                                                    </span>
                                                </div>
                                                <Col className='col-auto'>
                                                    <div className='icon icon-shape bg-warning text-white rounded-circle shadow'>
                                                        <i className='fas fa-chart-pie' />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <div className='mt-3 mb-0'> </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md='3'>
                                    <Card className='card-stats mb-4 mb-xl-0'>
                                        <CardBody>
                                            <Row>
                                                <div className='col'>
                                                    <CardTitle
                                                        tag='h5'
                                                        className='text-uppercase text-muted mb-0'
                                                    >
                                                        Total Chats
                                                    </CardTitle>
                                                    <span className='h2 font-weight-bold mb-0'>
                                                        {dataMonthly.totalMessages || '0'}
                                                    </span>
                                                </div>
                                                <Col className='col-auto'>
                                                    <div className='icon icon-shape bg-yellow text-white rounded-circle shadow'>
                                                        <i className='fas fa-users' />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <div className='mt-3 mb-0'> </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row className='mt-4'>
                                <Col md='12'>
                                    <CardHeader>
                                        <CardTitle>
                                            <h3>Statistik Operator</h3>
                                        </CardTitle>
                                    </CardHeader>
                                </Col>
                            </Row>
                            <Row className=''>
                                {dataUsers.map((user) => {
                                    return (
                                        <Col md='3' key={user.user._id} className='mt-4'>
                                            <Card className='card-stats mb-4 mb-xl-0'>
                                                <CardHeader>
                                                    <CardTitle>{user.user.name}</CardTitle>
                                                </CardHeader>
                                                <CardBody>
                                                    <Row className='mb-2'>
                                                        <div className='col'>
                                                            <CardTitle
                                                                tag='h5'
                                                                className='text-uppercase text-muted mb-0'
                                                            >
                                                                Transferred
                                                            </CardTitle>
                                                            <span className='h2 font-weight-bold mb-0'>
                                                                {user.transferred || '0'}
                                                            </span>
                                                        </div>
                                                        {/* <Col className='col-auto'>
                                                            <div className='icon icon-shape bg-info text-white rounded-circle shadow'>
                                                                <i className='fas fa-chart-pie' />
                                                            </div>
                                                        </Col> */}
                                                    </Row>
                                                    <Row>
                                                        <Col md='6'>
                                                            <CardTitle
                                                                tag='h5'
                                                                className='text-uppercase text-muted mb-0'
                                                            >
                                                                Solved
                                                            </CardTitle>
                                                            <span className='h2 font-weight-bold mb-0'>
                                                                {user.solved || '0'}
                                                            </span>
                                                        </Col>
                                                        <Col md='6'>
                                                            <CardTitle
                                                                tag='h5'
                                                                className='text-uppercase text-muted mb-0'
                                                            >
                                                                Unsolved
                                                            </CardTitle>
                                                            <span className='h2 font-weight-bold mb-0'>
                                                                {user.unsolved || '0'}
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    );
                                })}
                            </Row>
                        </div>
                    </Container>
                </Header>

                {/* <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="8">
            <Card className="bg-gradient-default shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-light ls-1 mb-1">
                      Overview
                    </h6>
                    <h2 className="text-white mb-0">Performa Chat</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="pb-10">
                  <span>Performa Chat</span>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                      Overview
                    </h6>
                    <h2 className="mb-0">Pesan Masuk</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="pb-10">
                  <span>Pesan Masuk</span>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container> */}
            </>
        );
    }
}

export default Dashboard;
