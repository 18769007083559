import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Switch, Redirect } from 'react-router-dom';
import { renderRoutes } from '../routes';

function AuthLayout(props) {
    return (
        <div className='main-content'>
            <div className='header py-7 py-lg-8' style={{ backgroundColor: '#095F0F' }}></div>

            <Container className='mt--8 pb-5'>
                <Row className='justify-content-center'>
                    <Switch>
                        {renderRoutes({ layout: '/auth' })}
                        <Redirect from='*' to='/auth/login' />
                    </Switch>
                </Row>
            </Container>
        </div>
    );
}

export default AuthLayout;
