import React, { createContext, useContext, useReducer, useEffect } from 'react';
import axios from 'axios';

const ACTION = {
    RESTORE_TOKEN: 'RESTORE_TOKEN',
    REMOVE_TOKEN: 'REMOVE_TOKEN',
    LOADING: 'LOADING',
    LOADED: 'LOADED',
};

const ACCESS_TOKEN = 'ACCESS_TOKEN';

const AuthContext = createContext();

function AuthProvider(props) {
    const { RESTORE_TOKEN, REMOVE_TOKEN, LOADING, LOADED } = ACTION;

    const [state, dispatch] = useReducer(
        (prevState, action) => {
            switch (action.type) {
                case RESTORE_TOKEN:
                    return {
                        ...prevState,
                        token: action.token,
                        isAuthenticated: true,
                    };
                case REMOVE_TOKEN:
                    return {
                        ...prevState,
                        isAuthenticated: false,
                        token: null,
                    };
                case LOADING:
                    return {
                        ...prevState,
                        isLoading: true,
                        authError: null,
                    };
                case LOADED:
                    return {
                        ...prevState,
                        isLoading: false,
                        authError: action.error,
                    };
                default:
                    return prevState;
            }
        },
        {
            isLoading: false,
            isAuthenticated: false,
            token: null,
            authError: null,
        }
    );

    useEffect(() => {
        const bootstrapAsync = async () => {
            dispatch({ type: LOADING });

            let error;
            try {
                const token = localStorage.getItem(ACCESS_TOKEN);

                if (token) {
                    dispatch({ type: RESTORE_TOKEN, token });
                    axios.defaults.headers.common['Authorization'] = token;
                }
            } catch (err) {
                error = err;
            }

            dispatch({ type: LOADED, error });
        };

        bootstrapAsync();
    }, []);

    const value = {
        signIn: async (data) => {
            dispatch({ type: LOADING });
            let error;

            try {
                let response = await axios.post('/api/v2/users/login', data);
                response = response.data;
                if (response.success) {
                    const token = response.data.token;
                    localStorage.setItem(ACCESS_TOKEN, token);
                    axios.defaults.headers.common['Authorization'] = token;

                    dispatch({ type: RESTORE_TOKEN, token });
                }
            } catch (err) {
                alert('Login gagal, pastikan username dan password benar');
                error = err;
            }

            dispatch({ type: LOADED, error });
        },
        signOut: async () => {
            dispatch({ type: LOADING });
            let error;

            try {
                localStorage.removeItem(ACCESS_TOKEN);
                axios.defaults.headers.common['Authorization'] = null;
                dispatch({ type: REMOVE_TOKEN });
            } catch (err) {
                error = err;
            }

            dispatch({ type: LOADED, error });
        },
        ...state,
    };

    return <AuthContext.Provider value={value} {...props} />;
}

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
