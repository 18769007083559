import React, { useState, useEffect, useRef } from 'react';
import { Card, Row, Col, Form, FormGroup, CardBody, Input, Button } from 'reactstrap';
import axios from 'axios';

function ResponseCard(props) {
    const { data, handleOnChange, index, onRemove, onSave } = props;
    const [operators, setOperators] = useState(null);
    // WIP Menu BUTTON const [listButton, setListButton] = useState([]);
    const fileInputRef = useRef(null);

    useEffect(() => {
        fetchOperators();
    }, []);

    const fetchOperators = async () => {
        try {
            const result = await axios.get('/api/v2/users/admin');
            setOperators(result.data.data);
        } catch (error) {
            console.log(error);
        }
    };

    const renderAction = (type, options = null) => {
        if (type === 'file') {
            return (
                <Row>
                    <Col>
                        <FormGroup>
                            <label htmlFor='image'>Pilih file</label>
                            <Input
                                id='image'
                                type='file'
                                className='form-control-alternative'
                                ref={fileInputRef}
                                onChange={(e) => handleOnChange(index, e)}
                                name='action'
                            />
                        </FormGroup>
                    </Col>
                </Row>
            );
        } else if (type === 'transfer') {
            return options ? (
                <Row>
                    <Col>
                        <FormGroup>
                            <label htmlFor='type'>Operator Tujuan</label>
                            <Input
                                id='type'
                                type='select'
                                className='form-control-alternative'
                                value={data.action || ''}
                                required
                                onChange={(el) => handleOnChange(index, el)}
                                name='action'
                            >
                                <option value='' hidden>
                                    Operator
                                </option>
                                {operators.map((operator, opIndex) => {
                                    return (
                                        <option key={opIndex} value={operator._id}>
                                            {operator.name}
                                        </option>
                                    );
                                })}
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
            ) : (
                <p>Loading...</p>
            );
            // WIP Menu BUTTON
            // } else if (type === 'button') {
            //     return (
            //         <Row>
            //             <Col>
            //                 <FormGroup>
            //                     <label>Judul Menu</label>
            //                     <Input type='text' placeholder='Judul menu'></Input>
            //                     <label>Deskripsi</label>
            //                     <Input type='text' placeholder='Teks deskripsi'></Input>
            //                     <label htmlFor='type'>List Button</label>
            //                     {listButton.map((data) => {
            //                         return (
            //                             <Input type='text' placeholder='Button name' onChange={() => {}}></Input>
            //                         );
            //                     })}
            //                     <Button
            //                         onClick={() => {
            //                             setListButton((listButton) => [...listButton, { body: '' }]);
            //                         }}
            //                     >
            //                         + tambah
            //                     </Button>
            //                     <Input
            //                         id='button'
            //                         type='text'
            //                         className='form-control-alternative'
            //                         placeholder='Button name'
            //                         name='button'
            //                         value={data.action || ''}
            //                         onChange={(el) => handleOnChange(index, el)}
            //                         required
            //                     />
            //                 </FormGroup>
            //             </Col>
            //         </Row>
            //     );
            // ---///
        }

        // Default text
        return (
            <Row>
                <Col>
                    <FormGroup>
                        <label htmlFor='action'>Action</label>
                        <Input
                            id='action'
                            type='textarea'
                            rows='6'
                            className='form-control-alternative'
                            placeholder='Action*'
                            name='action'
                            value={data.action || ''}
                            onChange={(el) => handleOnChange(index, el)}
                            required
                        />
                    </FormGroup>
                </Col>
            </Row>
        );
    };

    return (
        <Card className='shadow mb-3'>
            <CardBody className='border-0'>
                <Form>
                    <Row>
                        <Col>
                            <FormGroup>
                                <label htmlFor='command'>Command</label>
                                <Input
                                    id='command'
                                    className='form-control-alternative'
                                    placeholder='Command*'
                                    name='command'
                                    value={data.command || ''}
                                    onChange={(el) => handleOnChange(index, el)}
                                    required
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <label htmlFor='type'>Tipe action</label>
                                <Input
                                    id='type'
                                    type='select'
                                    className='form-control-alternative'
                                    value={data.type || ''}
                                    required
                                    onChange={(el) => handleOnChange(index, el)}
                                    name='type'
                                >
                                    <option value='' hidden>
                                        Tipe*
                                    </option>
                                    {/* WIP Menu BUTTON<option value='button'>Button</option> */}
                                    <option value='text'>Text</option>
                                    <option value='file'>File</option>
                                    <option value='transfer'>Transfer Pesan</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>

                    {data.type && renderAction(data.type, operators)}
                </Form>

                <Button color='danger' onClick={onRemove}>
                    Hapus
                </Button>
                <Button color='primary' className='float-right' onClick={onSave}>
                    Simpan
                </Button>
            </CardBody>
        </Card>
    );
}

export default ResponseCard;
