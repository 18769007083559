import React, { useEffect, useRef } from "react";
import { Switch, Redirect, useHistory } from "react-router-dom";
import { Container } from "reactstrap";
import axios from "axios";

import Sidebar from "../components/Sidebars/Sidebar";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import AdminFooter from "../components/Footers/AdminFooter";
import { renderRoutes, getBrandText, routes } from "../routes";
import { useAuth } from "../contexts/auth";

import imageLogo from "../assets/img/brand/logo_uin.png";

function AdminLayout(props) {
  const mainContentRef = useRef(null);
  const { signOut } = useAuth();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, []);

  return (
    <>
      <Sidebar
        {...props}
        routes={routes.filter((route) => route.layout === "/admin")}
        logo={{
          innerLink: "/admin/dashboard",
          imgSrc: imageLogo,
          imgAlt: "UIN Logo",
        }}
        onLogout={signOut}
      />

      <div className="main-content" ref={mainContentRef}>
        <AdminNavbar
          {...props}
          brandText={getBrandText({ path: props.location.pathname })}
          onLogout={signOut}
        />

        <Switch>
          {renderRoutes({ layout: "/admin" })}
          <Redirect from="*" to="/admin/dashboard" />
        </Switch>

        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
}

export default AdminLayout;
